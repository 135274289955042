import { useState } from "react";
import Rooms from "../components/rooms";
import "../styles/roomDetail.css";

const RoomDetail = ({ roomA,roomB }) => {
  const [select, setSelect] = useState("A");
  return (
    <div className="RoomDetail-container">
      <div className="roomBar">
        <button
          className={`btn btn-edit ${select === "A" ? "active" : ""}`}
          onClick={() => setSelect("A")}
        >
          ตึก A
        </button>
        <button
          className={`btn btn-edit ${select === "B" ? "active" : ""}`}
          onClick={() => setSelect("B")}
        >
          ตึก B
        </button>
        <div className="occ">
          <div className="green"></div>
          <p>ว่าง</p>
        </div>
        <div className="occ">
          <div className="red"></div>
          <p>ไม่ว่าง</p>
        </div>
      </div>
      <div className="items-container">
        {select === "A"
          ? roomA.map((room) => (
              <div className="roomItems" key={room._id}>
                <Rooms
                  room={room}
                />
              </div>
            ))
          : roomB.map((room) => (
              <div className="roomItems" key={room._id}>
                <Rooms
                  room={room}
                />
              </div>
            ))}
      </div>
    </div>
  );
};

export default RoomDetail;
