import { useContext } from "react";
import { RoomContext } from "contexts/roomContext";

export const useRoomContext = () => {
  const context = useContext(RoomContext);
  if (!context) {
    throw Error("useRoomContext must be used inside an RoomProviders");
  }
  return context;
};
