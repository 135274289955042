import React, { useState } from "react";
import { useUserContext } from "hooks/useUserContext";
import { publicRequest } from "publicRequest";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const { dispatch } = useUserContext();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const handleLogin = async () => {
    dispatch({ type: "LOGIN_START" });
    try {
      const res = await publicRequest.post("/apartment/login", {
        username,
        password,
      });
      if (res.status !== 200) {
        dispatch({ type: "LOGIN_FAILURE" });
        setError("Invalid username or password");
        return;
      }
      const data = await res.data;
      dispatch({ type: "LOGIN_SUCCESS", payload: data });
      setError(null);
      setUsername("");
      setPassword("");
      alert("ล๊อคอินสำเร็จ");
      navigate('/');
    } catch (error) {
      console.error("Login failed:", error);
      dispatch({ type: "LOGIN_FAILURE" });
      setError("Login failed. Please try again later.");
      alert("รหัสผ่านไม่ถูกต้อง!!!");
    }
  };
  return (
    <div className="Login-container">
      <h2>Login</h2>
      <input
        type="text"
        placeholder="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button onClick={handleLogin}>Login</button>
      {error && <p className="error">{error}</p>}
    </div>
  );
};

export default Login;
