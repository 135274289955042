import { useContext } from "react";
import { ElectricContext } from "contexts/electricContext";

export const useElectricContext = () => {
  const context = useContext(ElectricContext);
  if (!context) {
    throw Error("useElectricContext must be used inside an ElectricProviders");
  }
  return context;
};
