import { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useUserContext } from "hooks/useUserContext";
import logo from "../images/logo.jpg";
import WifiOutlinedIcon from "@mui/icons-material/WifiOutlined";
import { Home } from "@mui/icons-material";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import KingBedOutlinedIcon from "@mui/icons-material/KingBedOutlined";
import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined";
import InsertPhotoOutlinedIcon from "@mui/icons-material/InsertPhotoOutlined";
import QuickreplyOutlinedIcon from "@mui/icons-material/QuickreplyOutlined";
import TransferWithinAStationOutlinedIcon from '@mui/icons-material/TransferWithinAStationOutlined';
import { Outlet, useNavigate } from "react-router-dom";
import Header from "components/header";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const watchItems = [
  {
    text: "หน้าหลัก",
    icon: <Home />,
  },
  {
    text: "ดูรายการห้องพัก",
    icon: <KingBedOutlinedIcon />,
  },
  {
    text: "ค่าไฟย้อนหลัง",
    icon: <QuickreplyOutlinedIcon />,
  },
  {
    text: "ประวัติการเข้าพัก",
    icon: <TransferWithinAStationOutlinedIcon />,
  },
];

const addItems = [
  {
    text: "เพิ่มห้องพัก",
    icon: <RoomPreferencesIcon />,
  },
  {
    text: "เพิ่มรหัสไวไฟ",
    icon: <WifiOutlinedIcon />,
  },
  {
    text: "เพิ่มค่าปรับ",
    icon: <AttachMoneyOutlinedIcon />,
  },
  {
    text: "เพิ่มค่าไฟ",
    icon: <BoltOutlinedIcon />,
  },
  {
    text: "เพิ่มรูปห้อง",
    icon: <InsertPhotoOutlinedIcon />,
  },
];

const Layout = ({active,setActive}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [openAdd, setOpenAdd] = useState(true);
  const { state } = useUserContext();
  const user = state.user;

  const handleDrawerOpen = () => {
    setOpen(true);
    if (!openAdd) {
      setOpenAdd(true);
    }
  };
  const handleDrawerClose = () => {
    setOpen(false);
    setOpenAdd(true);
  };
  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar
        sx={{
          backgroundColor:`var(--blue1)`
        }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingLeft: "10px",
              paddingRight: "30px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={logo}
                alt=""
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  border: "0.5px solid black",
                  marginRight: "15px",
                }}
              />
              <Typography variant="h6" noWrap component="div">
                Baan Thananatsorn
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifySelf: "end",
              }}
            >
              {user ? (
                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                  {user.username}
                </Typography>
              ) : (
                <Typography vaiant="h6">Login</Typography>
              )}
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              marginLeft: "30px",
              alignItmes: "center",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: 600,
              }}
            >
              Dashboard
            </Typography>
          </Box>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List
          sx={{
            padding: 0,
          }}
        >
          {watchItems.map(({ text, icon }) => (
            <ListItem key={text} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 40,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                onClick={() => {
                  navigate(`/${text}`);
                  setActive(text);
                }}
                title={text}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {icon}
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        <Box
          sx={{
            margin: "10px",
            color: "grey",
            justifyContent: "space-between",
            alignItems: "center",
            display: open ? "flex" : "none",
            opacity: open ? 1 : 0,
            cursor: "pointer",
          }}
          onClick={() => setOpenAdd(!openAdd)}
        >
          <Typography>เพิ่มข้อมูล</Typography>
          {openAdd ? <ExpandLess /> : <ExpandMore />}
        </Box>
        <Collapse in={openAdd} timeout="auto" unmountOnExit>
          <List
            sx={{
              padding: 0,
            }}
          >
            {addItems.map(({ text, icon }) => (
              <ListItem key={text} disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 40,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  onClick={() => {
                    navigate(`/${text}`);
                    setActive(text);
                  }}
                  title={text}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {icon}
                  </ListItemIcon>
                  <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Collapse>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          marginTop: "64px",
          overflowY: "hidden",
          width: `calc(100% - 100px)`,
        }}
      >
        <Header active={active} />
        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout;
