const Rooms = ({ room }) => {
  return (
    <div className="roomItemContainer">
      <h2>
        {room.room} {room.type}
      </h2>
      <h4>ชื่อ : {room.Fname}</h4>
      <h4>นามสกุล : {room.Sname}</h4>
      <h4>เพศ : {room.sex}</h4>
      <h4>เบอร์ : {room.phone}</h4>
      {room.occ === "occupied" ? (
        <div className="red status"></div>
      ) : (
        <div className="green status"></div>
      )}
    </div>
  );
};

export default Rooms;
