import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { RoomContextProvider } from "contexts/roomContext";
import { UserContextProvider } from "contexts/userContext";
import { ElectricContextProvider } from "contexts/electricContext";
import { publicRequest } from "publicRequest";
import { useRoomContext } from "hooks/useRoomContext";
import Login from "views/login";
import { useUserContext } from "hooks/useUserContext";
import Layout from "views/layout";
import MainPage from "views/mainPage";
import EditInfo from "views/editInfo";
import RoomDetail from "views/roomsDetail";
import AddElectric from "views/addElectric";

const App = ({ user }) => {
  const { state, dispatch } = useRoomContext();
  const Token = user?.accessToken;
  const [active, setActive] = useState("หน้าหลัก");
  useEffect(() => {
    const getRoom = async () => {
      try {
        const res = await publicRequest.get("/apartment/getrooms", {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        });
        if (res.status === 200) {
          const rooms = res.data.rooms;
          const roomA = res.data.roomA;
          const roomB = res.data.roomB;
          dispatch({ type: "GET_ROOMS", payload: { rooms, roomA, roomB } });
        }
      } catch (err) {
        console.log(err);
      }
    };
    getRoom();
  }, [dispatch, Token]);
  const rooms = state.rooms.rooms || [];
  const roomA = state.rooms.roomA || [];
  const roomB = state.rooms.roomB || [];
  return (
    <div className="App">
      <Routes>
        {user ? (
          <Route element={<Layout active={active} setActive={setActive} />}>
            <Route path="/" element={<Navigate to="/หน้าหลัก" replace />} />
            <Route path="/หน้าหลัก" element={<MainPage />} />
            <Route path="/เพิ่มห้องพัก" element={<EditInfo />} />
            <Route
              path="/เพิ่มค่าไฟ"
              element={<AddElectric rooms={rooms} Token={Token} />}
            />
            <Route
              path="/ดูรายการห้องพัก"
              element={<RoomDetail roomA={roomA} roomB={roomB} />}
            />
          </Route>
        ) : (
          <Route path="/" element={<Navigate to="/login" replace />} />
        )}
        <Route path="/login" element={<Login />} />
      </Routes>
    </div>
  );
};

const APp = () => {
  const { state } = useUserContext();
  const user = state.user;
  return <App user={user} />;
};

const APP = () => {
  return (
    <BrowserRouter>
      <RoomContextProvider>
        <ElectricContextProvider>
          <UserContextProvider>
            <APp />
          </UserContextProvider>
        </ElectricContextProvider>
      </RoomContextProvider>
    </BrowserRouter>
  );
};

export default APP;
