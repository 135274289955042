import { Box,Typography } from "@mui/material";

const Header = ({active}) => {
  return (
    <div className='Header-container'>
      <Box>
      <Typography
        variant="h4"
        fontWeight="bold"
        sx={{ p:"20px",color:"#fff", pl:"50px",bgcolor:"var(--blue4)"}}
      >
        {active}
      </Typography>
    </Box>
    </div>
  );
};

export default Header;