import "../styles/addElectric.css";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import { useElectricContext } from "hooks/useElectricContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { publicRequest } from "publicRequest";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "var(--blue1)",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(even)": {
    backgroundColor: "rgb(170, 215, 255)",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const AddElectric = ({ rooms, Token }) => {
  const { state, dispatch } = useElectricContext();
  const electric = state.electrics || [];
  const [Evalues, setEvalues] = useState({});
  const [Euses, setEuses] = useState({});
  const [Eprices, setEprices] = useState({});
  const currentDate = new Date();
  const defaultYear = (currentDate.getFullYear() + 543).toString();
  const defaultMonth = new Intl.DateTimeFormat("en-US", { month: "long" })
    .format(currentDate)
    .toLowerCase();
  const [year, setYear] = useState(defaultYear);
  const [month, setMonth] = useState(defaultMonth);

  const monthNames = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
  ];
  const showEunit = (room) => {
    const electricData = electric.find((item) => item.roomID === room._id);
    if (electricData) {
      const yearToSearch = month === "january" ? parseInt(year) - 1 : year;
      const monthToSearch =
        month === "january"
          ? "december"
          : monthNames[monthNames.indexOf(month) - 1];

      const yearlyData = electricData.yearly.find(
        (item) => item.year === yearToSearch
      );
      if (yearlyData) {
        const monthlyData = yearlyData.monthly.find(
          (item) => item.month === monthToSearch
        );
        if (monthlyData) {
          return monthlyData.Eunit;
        }
      }
    }
    return 0;
  };
  useEffect(() => {
    const getElectrics = async () => {
      try {
        const res = await publicRequest.get(
          "/apartment/getelectrics",
          {
            headers: {
              Authorization: `Bearer ${Token}`,
            },
          }
        );
        if (res.status === 200) {
          const json = res.data;
          dispatch({ type: "GET_ELECTRICS", payload: json });
        }
      } catch (err) {
        alert(err);
      }
    };
    getElectrics();
  }, [dispatch, Token]);
  const handleElectricChange = (roomId, value) => {
    setEvalues((prev) => ({
      ...prev,
      [roomId]: value,
    }));
    const room = rooms.find((room) => room._id === roomId);
    if (room) {
      const oldUnit = showEunit(room);
      const prevPrice = oldUnit;
      const newPrice = parseFloat(value);
      const diff = newPrice - prevPrice;
      setEuses((prev) => ({
        ...prev,
        [roomId]: diff,
      }));
      setEprices((prev) => ({
        ...prev,
        [roomId]: diff * 8,
      }));
    }
  };
  const handleYearChange = (date) => {
    setYear(date.getFullYear().toString());
    const selectedMonth = date
      .toLocaleString("eng", { month: "long" })
      .toLowerCase();
    setMonth(selectedMonth);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      roomIDs: Object.keys(Evalues),
      Evalues,
      Euses,
      Eprices,
      year,
      month,
    };
    try {
      const res = publicRequest.post(
        "/apartment/createelectrics",
        data,
        {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        }
      );
      if (res.status === 201) {
        const json = res.data;
        alert("บันทึกข้อมูลเรียบร้อยแล้ว");
        setEvalues({});
        setEuses({});
        setEprices({});
        dispatch({ type: "CREATE_ELECTRICS", payload: json });
      } else {
        alert("!!!บันทึกข้อมูลไม่สำเร็จ");
      }
    } catch (err) {
      alert(`${err.message}`);
    }
  };

  return (
    <div className="AddElectric-container">
      <div className="roomBar">
        <DatePicker
          selected={new Date(year, monthNames.indexOf(month))}
          onChange={handleYearChange}
          dateFormat="MMMM / yyyy"
          showMonthYearPicker
        />
      </div>
      <TableContainer
        component={Paper}
        sx={{ maxHeight: 600, overflow: "auto" }}
      >
        <Table sx={{ minWidth: 600 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>ห้อง</StyledTableCell>
              <StyledTableCell align="center">
                ค่าไฟเดือนที่แล้ว
              </StyledTableCell>
              <StyledTableCell align="center">ค่าไฟใหม่</StyledTableCell>
              <StyledTableCell align="center">ส่วนต่าง</StyledTableCell>
              <StyledTableCell align="center">หน่วยละ</StyledTableCell>
              <StyledTableCell align="center">ราคารวม</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rooms.map((room) => (
              <StyledTableRow key={room._id}>
                <StyledTableCell component="th" scope="row">
                  {`${room.room}${room.type}`}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {showEunit(room)}
                </StyledTableCell>
                <StyledTableCell align="center">
                  <input
                    type="text"
                    placeholder="ค่าไฟใหม่"
                    value={Evalues[room._id] || ""}
                    onChange={(e) =>
                      handleElectricChange(room._id, e.target.value)
                    }
                  />
                </StyledTableCell>
                <StyledTableCell align="center">
                  {Euses[room._id] || 0}
                </StyledTableCell>
                <StyledTableCell align="center">8</StyledTableCell>
                <StyledTableCell align="center">
                  {Eprices[room._id] || 0} บาท
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="submit-cover">
        <button className="btn-secondary">เคลียร์ข้อมูล</button>
        <button className="btn-green" onClick={handleSubmit}>
          บันทึกข้อมูล
        </button>
      </div>
    </div>
  );
};

export default AddElectric;
