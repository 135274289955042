import { useState } from "react";
import "../styles/editInfo.css";
import { publicRequest } from "publicRequest";
import { useUserContext } from "hooks/useUserContext";

const EditInfo = () => {
  const { state } = useUserContext();
  const user = state.user;
  const [type, setType] = useState("A");
  const [room, setRoom] = useState("");
  const [ID, setID] = useState("");
  const [sex, setSex] = useState("male");
  const [Fname, setFname] = useState("");
  const [Sname, setSname] = useState("");
  const [address, setAddress] = useState("");
  const [Estatus, setEstatus] = useState("true");
  const [phone, setPhone] = useState("");
  const [occ, setOcc] = useState("occupied");
  const [price, setPrice] = useState("");
  const [CED, setCED] = useState("");
  const [studentID, setStudentID] = useState("");
  const [uni, setUni] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const handleClick = async (e) => {
    e.preventDefault();
    try {
      const res = await publicRequest.post(
        "/apartment/createroom",
        {
          type,
          room,
          ID,
          sex,
          Fname,
          Sname,
          address,
          Estatus,
          phone,
          occ,
          price,
          CED,
          studentID,
          uni,
          username,
          password,
        },
        {
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
        }
      );
      if (res.status === 200) {
        setType("A");
        setRoom("");
        setID("");
        setSex("male");
        setFname("");
        setSname("");
        setAddress("");
        setEstatus("true");
        setPhone("");
        setOcc("occupied");
        setPrice("");
        setCED("");
        setStudentID("");
        setUni("");
        setUsername("");
        setPassword("");
        console.log(res.data);
        alert("บันทึกข้อมูลเรียบร้อยแล้ว");
      }
    } catch (err) {
      console.log(err);
      alert(`Error : ${err.message}`);
    }
  };
  const clear = () => {
    setType("A");
    setRoom("");
    setID("");
    setSex("male");
    setFname("");
    setSname("");
    setAddress("");
    setEstatus(true);
    setPhone("");
    setOcc("occupied");
    setPrice("");
    setCED("");
    setStudentID("");
    setUni("");
    setUsername("");
    setPassword("");
    alert("เคลียข้อมูลเรียร้อย!!!");
  };
  const handleStatus=()=>{
    setEstatus((e)=>!e)
  }
  return (
    <div className="specialEditnfo">
      <div className="EditInfo-cover">
        <form action="" method="post" onSubmit={handleClick}>
          <div className="EditInfo">
            <div className="formBox">
              <div className="infoBlock">
                <p>ตึกที่อยู่ / Building</p>
                <select
                  className="centered"
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  required
                >
                  <option>A</option>
                  <option>B</option>
                </select>
              </div>
              <div className="infoBlock">
                <p>เลขห้อง / Room Number</p>
                <input
                  type="number"
                  value={room}
                  placeholder="กรุณาใส่เลขห้อง"
                  onChange={(e) => setRoom(e.target.value)}
                  required
                />
              </div>
              <div className="infoBlock">
                <p>เลขบัตรประจำตัวประชาชน / Personal ID</p>
                <input
                  type="number"
                  value={ID}
                  placeholder="กรุณาใส่เลขบัตรประจำตัวประชาชน"
                  onChange={(e) => setID(e.target.value)}
                  required
                />
              </div>
              <div className="infoBlock">
                <p>เพศ / Gender</p>
                <select
                  className="centered"
                  value={sex}
                  onChange={(e) => setSex(e.target.value)}
                  required
                >
                  <option value="male">ชาย</option>
                  <option value="female">หญิง</option>
                </select>
              </div>
              <div className="infoBlock">
                <p>ชื่อจริง / Firstname</p>
                <input
                  type="text"
                  value={Fname}
                  placeholder="กรุณาใส่ชื่อจริง"
                  onChange={(e) => setFname(e.target.value)}
                  required
                />
              </div>
              <div className="infoBlock">
                <p>นามสกุล / Surname</p>
                <input
                  type="text"
                  value={Sname}
                  placeholder="กรุณาใส่นามสกุล"
                  onChange={(e) => setSname(e.target.value)}
                  required
                />
              </div>
              <div className="infoBlock">
                <p>ที่อยู่ / Address</p>
                <textarea
                  cols="30"
                  rows="10"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  required
                ></textarea>
              </div>
            </div>
            <div className="formBox">
              <div className="infoBlock">
                <p>สถานะการศึกษา / Education status</p>
                <select
                  className="centered"
                  value={Estatus}
                  onChange={handleStatus}
                  required
                >
                  <option value="true">ศึกษาอยู่</option>
                  <option value="false">ทำงานแล้ว</option>
                </select>
              </div>
              <div className="infoBlock">
                <p>สถานศึกษา / University</p>
                <input
                  type="text"
                  value={uni}
                  placeholder="กรุณาใส่สถานศึกษา"
                  onChange={(e) => setUni(e.target.value)}
                />
              </div>
              <div className="infoBlock">
                <p>เลขประจำตัวนักศึกษา / Student ID</p>
                <input
                  type="number"
                  value={studentID}
                  placeholder="กรุณาใส่เลขประจำตัวนักศึกษา"
                  onChange={(e) => setStudentID(e.target.value)}
                />
              </div>
              <div className="infoBlock">
                <p>เบอร์โทร / Phone number</p>
                <input
                  type="number"
                  value={phone}
                  placeholder="กรุณาใส่เบอร์โทร"
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
              </div>
              <div className="infoBlock">
                <p>ราคาค่าห้อง / Price</p>
                <input
                  type="number"
                  value={price}
                  placeholder="กรุณาใส่ราคาห้องพัก"
                  onChange={(e) => setPrice(e.target.value)}
                  required
                />
              </div>
              <div className="infoBlock">
                <p>สถานะห้องพัก / OCC</p>
                <select
                  className="centered"
                  value={type}
                  onChange={(e) => setOcc(e.target.value)}
                  required
                >
                  <option value="occupied">ไม่ว่าง</option>
                  <option value="unoccupied">ว่าง</option>
                </select>
              </div>
              <div className="infoBlock">
                <p>วันหมดสัญญา / CED</p>
                <input
                  type="date"
                  value={CED}
                  placeholder="กรุณาใส่วันหมดสัญญา"
                  onChange={(e) => setCED(e.target.value)}
                  required
                />
              </div>
              <div className="infoBlock">
                <p>ID</p>
                <input
                  type="text"
                  value={username}
                  placeholder="กรุณาใส่ ID"
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </div>
              <div className="infoBlock">
                <p>Password</p>
                <input
                  type="text"
                  value={password}
                  placeholder="กรุณาใส่ Password"
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
            </div>
          </div>
          <div className="btn-cover">
            <div className="btn-cover-cover">
              <button type="submit" className="btn btn-success">
                Submit
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={clear}
              >
                Clear
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditInfo;
