import { createContext, useReducer } from "react";
export const RoomContext = createContext();
const initialState = {
  rooms:[],
  loading: false,
  error: null,
};
export const roomReducer = (state, action) => {
  switch (action.type) {
    case "CREATE_ROOM":
      return {
        ...state,
        rooms: [action.payload, ...state.rooms],
      };
    case "GET_ROOMS":
      return {
        ...state,
        rooms: action.payload,
        loading: false,
        error: null,
      };
    case "ROOMS_ERROR":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
export const RoomContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(roomReducer,initialState);
  return (
    <RoomContext.Provider value={{ state, dispatch }}>
      {children}
    </RoomContext.Provider>
  );
};
