import React from "react";
import { createRoot } from "react-dom/client"; 
import "./index.css";
import APP from "./App";

const root = createRoot(document.getElementById("root")); 
root.render(
  <React.StrictMode>
    <APP />
  </React.StrictMode>
);