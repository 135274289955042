import { createContext, useReducer } from "react";
export const ElectricContext = createContext();
const initialState = {
  electrics: [],
  loading: false,
  error: null,
};
export const electricReducer = (state, action) => {
  switch (action.type) {
    case "GET_ELECTRICS":
      return {
        ...state,
        electrics: action.payload,
        loading: false,
        error: null,
      };
    case "ELECTRICS_ERROR":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      case "CREATE_ELECTRICS":
        return{
          ...state,
          electrics:[action.payload,...state.electrics]
        }
    default:
      return state;
  }
};
export const ElectricContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(electricReducer, initialState);
  return (
    <ElectricContext.Provider value={{ state, dispatch }}>
      {children}
    </ElectricContext.Provider>
  );
};
